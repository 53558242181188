<template>
  <div class="card-box navigate-cell-wrapper">
    <div class="btn favorite-btn" v-if="showFavorite" @click.stop="favorite">
      <van-icon :name="isFavorite ? 'star' : 'star-o'" />
    </div>
    <div class="info-wrapper">
      <div class="title-wrapper">
        <div class="btn navigate-btn" v-if="showNavigate" @click.stop="navigate">
          <div class="navigate-icon"></div>
        </div>
        <h4 class="van-ellipsis">{{title}}</h4>
      </div>
      <p class="distance" v-if="distance">{{distanceStr + '  ' + label }}</p>

      <!-- <p class="label">{{label}}</p> -->
      <slot name="desc">
        <p class="desc">{{desc}}</p>
      </slot>
    </div>
  </div>
</template>

<script>
import isNumber from 'is-number'
export default {
  name: 'navigateCell',
  props: {
    isFavorite: {
      type: Boolean,
      default: false
    },
    showFavorite: {
      type: Boolean,
      default: true
    },
    showNavigate: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    distance: {
      type: [Number, String]
    }
  },
  computed: {
    distanceStr () {
      if (!isNumber(this.distance)) return ''
      let distance = this.distance * 1
      if (distance < 1e3) {
        return distance.toFixed(1) + '米'
      } else if (distance < 1e5) {
        return distance % 1e3 === 0 ? distance / 1e3 + '公里' : (distance / 1e3).toFixed(1) + '公里'
      } else if (distance < 1e6) {
        return (distance < 1e5).toFixed(0) + '百公里'
      } else if (distance < 1e7) {
        return (distance / 1e6).toFixed(0) + '千公里'
      } else {
        return (distance / 1e7).toFixed(0) + '万公里'
      }
    }
  },
  methods: {
    navigate () {
      this.$emit('navigate')
    },
    favorite () {
      this.$emit('favorite', !this.isFavorite)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .navigate-cell-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    .info-wrapper{
      flex: 1;
      .title-wrapper{
        color: #666;
        font-size: 26px;
        h4{
          max-width: 300px;
          font-size: 32px;
          color: #333;
          font-weight: bold;
          font-size: 28px;
          line-height: 40px;
        }
        p{
          max-width: 180px;
        }
        .iconfont{
          color: #677881;
          font-size: 18px;
          margin-right: 12px;
          margin-left: 12px;
        }
        .navigate-btn{
          float: right;
        }
      }
      .label{
        font-size: 26px;
        color: #666;
        margin-top: 9px;
      }
      .distance{
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #666666;
        line-height: 34px;
        margin: 16px 0;
      }
      .desc{
        font-size: 24px;
        color: #9AA8B4;
        margin-top: 9px;
      }
    }
    .btn{
      width: 62px;
      height: 62px;
      margin-left: 32px;
      &.favorite-btn{
        margin-left: 12px;
        .van-icon{
          color: #FFCC01;
          font-size: 36px;
        }
      }

      .navigate-icon{
        @include bgImage('../assets/navigation', 60px, 60px);
      }
    }
  }
</style>
